import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home.js';
import VodPage from './vodpage.js';
import Header from './Header.js';
import Pros from './Pros.js';
import Footer from './Footer.js';

import Matches from './Matches.js';

import './index.css'
import Champions from './Champions.js';
import PrivacyPolicy from './PrivacyPolicy.js';
import TermsOfService from './TermsOfService.js';
import Imprint from './Imprint.js';

export default function App() {
  
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path='/' element={<Home />}></Route>
        <Route exact path='/vod/:matchId' element={<VodPage />}></Route>
        <Route exact path='/matches' element={<Matches />}></Route>
        <Route exact path='/matches/:param' element={<Matches />}></Route>
        <Route exact path='/pros' element={<Pros />}></Route>
        <Route exact path='/champions' element={<Champions />}></Route>
        <Route exact path='/privacy-policy' element={<PrivacyPolicy />}></Route>
        <Route exact path='/terms-of-service' element={<TermsOfService />}></Route>
        <Route exact path='/imprint' element={<Imprint />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);