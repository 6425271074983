import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.2/firebase-app.js";
import { getFirestore, collection, getDocs, where, query, getDoc, doc, updateDoc, Timestamp, limit, orderBy } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-firestore.js';
import { Link } from 'react-router-dom';


const firebaseConfig = {
    apiKey: "AIzaSyCM3NrGza2-Upzx6-ET9MTPAymqdhEigr8",
    authDomain: "lolvods-c3104.firebaseapp.com",
    projectId: "lolvods-c3104",
    storageBucket: "lolvods-c3104.appspot.com",
    messagingSenderId: "970001008408",
    appId: "1:970001008408:web:98f145176ac41e4de9006d",
    measurementId: "G-HQEWVSJ6J7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const VodPage = () => {
    window.scrollTo(0, 0);

    const { matchId } = useParams();
    const [playerName, setPlayerName] = useState("");
    const [match, setMatch] = useState(null);
    const [moreMatches, setMoreMatches] = useState([]);

    useEffect(() => {
        const getMoreMatches = async () => {
            var temp = []
            const q = query(collection(db, "matches"), limit(5), where("playerName", "==", playerName));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                temp.push({ id: doc.id, data: doc.data() });
            });
            setMoreMatches(temp);
        };
        getMoreMatches();

    }, [playerName])

    useEffect(() => {
        const getMatch = async () => {
            const docRef = doc(db, "matches", matchId);
            const docSnap = await getDoc(docRef);
            const matchData = docSnap.data();
            setMatch(matchData);
            setPlayerName(matchData.playerName);
        };
        getMatch();
    }, [matchId]);


    if (!match) {
        return <div>Loading...</div>;
    }

    function getRoleImg(roleNumber) {
        return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/roles%2Frole-${roleNumber}.webp?alt=media&token=c1e1c517-351a-4148-82bc-2a632ffa6a3d`
    }

    function getProImg(proName) {
        return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/images%2F${proName}.webp?alt=media&token=3bf086d9-8875-4680-b7f2-60c19c26b2f8`

    }
    function getChampionImg(championName) {
        return `https://ddragon.leagueoflegends.com/cdn/14.7.1/img/champion/${championName}.png`
    }
    return (
        <div className='bg-base-100 min-h-screen flex justify-center'>
            {/* Main Container */}
            <div className='w-full p-10 justify-center'>
                <div className='block lg:flex gap-4 justify-center'>
                    <div className='w-full order-2'>
                        {/* Embedded VOD */}
                        <iframe
                            className="vod-iframe"
                            src={`https://player.twitch.tv/?video=v${match.vod.vodId}&parent=${window.location.origin.replace("https://","")}&time=${match.vod.vodTime}`}
                            title="VOD Player"
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="">
                        <h1 className='text-2xl'>Current Match: </h1>
                        <div className="card bg-base-300 shadow-xl mt-5 lg:w-80">
                            <div className="card-body">
                                <figure>
                                    <img src={getProImg(match.playerName)} alt={match.playerName} className='object-contain h-40' />
                                </figure>
                                <div className="card-title justify-center" >
                                    <h1 className='text-2xl'>{match.playerName}</h1>
                                    <figure>
                                        <img src={getRoleImg(2)} alt="Role Image" className='object-contain w-10 h-10' />
                                    </figure>
                                </div>
                                <div className='flex justify-center gap-5 items-center'>
                                    <div className="card-title justify-center" >
                                        <figure>
                                            <img src={getChampionImg(match.championName)} alt="Role Image" className='object-contain w-24' />
                                        </figure>
                                    </div>
                                    <h1 className='text-2xl'>
                                        VS
                                    </h1>
                                    <div className="card-title justify-center" >
                                        <figure>
                                            <img src={getChampionImg(match.enemyChampionName)} alt="Role Image" className='object-contain w-24' />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Recommendations */}
                <div className=""> {/* Flex-grow to push recommendations to the bottom */}
                    <h1 className='text-2xl'>More from {match.playerName}: </h1>
                    <div className='flex flex-wrap 2xl:flex-nowrap justify-center'>
                        {moreMatches.map((match) => (
                            <div className="card  bg-base-300 shadow-xl m-4">
                                <Link to={`/vod/${match.id}`}> {/* Wrap each item with Link */}
                                    <div className="card-body ">
                                        <div className='flex justify-center gap-5 items-center '>
                                            <h1 className='text-xl '>
                                                {match.data.kda}
                                            </h1>
                                            <div className="card-title justify-center" >
                                                <figure>
                                                    <img src={getChampionImg(match.data.championName)} alt="Role Image" className='object-contain w-24 h-24' />
                                                </figure>
                                            </div>
                                            <h1 className='text-xl'>
                                                VS
                                            </h1>
                                            <div className="card-title justify-center" >
                                                <figure>
                                                    <img src={getChampionImg(match.data.enemyChampionName)} alt="Role Image" className='object-contain w-24 h-24' />
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>

                </div>
            </div>

        </div>
    );
};

export default VodPage;