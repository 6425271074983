import React, { useState, useEffect } from 'react';
// Import the functions you need from the SDKs you need
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.2/firebase-app.js";
import { getFirestore, collection, getDocs, where, query, getDoc, doc, updateDoc, Timestamp, limit, orderBy } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-firestore.js';

import { Link } from 'react-router-dom';


const firebaseConfig = {
  apiKey: "AIzaSyCM3NrGza2-Upzx6-ET9MTPAymqdhEigr8",
  authDomain: "lolvods-c3104.firebaseapp.com",
  projectId: "lolvods-c3104",
  storageBucket: "lolvods-c3104.appspot.com",
  messagingSenderId: "970001008408",
  appId: "1:970001008408:web:98f145176ac41e4de9006d",
  measurementId: "G-HQEWVSJ6J7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Home = () => {
  const [proList, setProList] = useState([]);
  const [vods, setVods] = useState([]);
  const [champions, setChampions] = useState([]);

  useEffect(() => {
    const getChampions = async () => {
      const docRef = await getDoc(doc(db, "stats", "champions"));

      const champions = docRef.data().mostPlayed;

      setChampions(champions);
    };
    getChampions();
  }, []); //

  useEffect(() => {
    const getPros = async () => {
      const docRef = await getDoc(doc(db, "stats", "players"));
      const proNames = docRef.data().mostMatches;

      const pros = [];

      const q = query(collection(db, "players"), limit(4), where("playerName", "in", proNames))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var pro = { id: doc.id, data: doc.data() }
        pros.push(pro);
      });
      setProList(pros);
    };

    getPros();
  }, []); // Empty dependency array ensures the effect runs only once after the component mounts

  useEffect(() => {

    const fetchData = async () => {

      var vodsData = [];
      const q = query(collection(db, "matches"), limit(5), orderBy("log.gameEnded", "desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        var vod = { id: doc.id, data: doc.data() }
        vodsData.push(vod);
      });
      setVods(vodsData);
    };
    fetchData();


  }, [db]); // Run effect whenever db changes


  function getRoleImg(roleNumber) {
    return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/roles%2Frole-${roleNumber}.webp?alt=media&token=c1e1c517-351a-4148-82bc-2a632ffa6a3d`
  }

  function getProImg(proName) {
    return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/images%2F${proName}.webp?alt=media&token=3bf086d9-8875-4680-b7f2-60c19c26b2f8`

  }
  function getChampionImg(championName) {
    return `https://ddragon.leagueoflegends.com/cdn/14.7.1/img/champion/${championName}.png`
  }

  return (
    <div className="bg-base-100 min-h-screen flex justify-center">
      <div className="max-w-screen-2xl mx-auto px-4">
        <div className="gap-10 flex flex-wrap xl:flex-nowrap mt-10 justify-center">
          <div className="">
            <div className="max-w-screen-xl mx-auto px-4">
              <h1 className="text-3xl mb-4 bg-text">Popular Players</h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                {proList.length === 0 ? (
                  <div className='flex col-span-1 sm:col-span-2 lg:col-span-4 h-44 gap-4'>
                    <div className="skeleton w-56 h-full bg-base-300 shadow-xl "></div>
                    <div className="skeleton w-56 h-full bg-base-300 shadow-xl "></div>
                    <div className="skeleton w-56 h-full bg-base-300 shadow-xl "></div>
                    <div className="skeleton w-56 h-full bg-base-300 shadow-xl"></div>
                  </div>
                ) : (
                  proList.map((pro) => (
                    <div key={pro.id} className="card bg-base-300 shadow-xl">
                      <Link to={`/matches/${pro.data.playerName}`} key={pro.id}>
                        <div className="card-body">
                          <figure>
                            <img src={getProImg(pro.data.playerName)} alt={pro.data.playerName} className="object-contain h-40" />
                          </figure>
                          <div className="card-title justify-center">
                            <h1 className="text-2xl">{pro.data.playerName}</h1>
                            <figure>
                              <img src={getRoleImg(pro.data.role)} alt="Role Image" className="object-contain w-10 h-10" />
                            </figure>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                )}
              </div>
            </div>

            <div className="mt-5 px-4">
              <h1 className="text-3xl mb-4 bg-text">Recent Matches</h1>
              <div className="grid grid-cols-1 gap-4 mx-auto" >
                {vods.length === 0 ? (
                  <div className="">
                    <div className="skeleton w-full h-36 bg-base-300 shadow-xl m-4"></div>
                    <div className="skeleton w-full h-36 bg-base-300 shadow-xl m-4 "></div>
                    <div className="skeleton w-full h-36 bg-base-300 shadow-xl m-4 "></div>
                  </div>
                ) : (
                  vods.map((vod) => (
                    <div key={vod.id} className="card neutral bg-base-300 shadow-xl">
                      <Link to={`/vod/${vod.id}`}>
                        <div className="card-body">
                          <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
                            <div className="flex items-center">
                              <img className="h-24" src={`https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/images%2F${vod.data.playerName}.webp?alt=media&token=3bf086d9-8875-4680-b7f2-60c19c26b2f8`} alt={vod.data.playerName} />
                              <img className="h-16" src={getRoleImg(vod.data.role)} alt={vod.data.role} />
                              <div className="ml-2 text-2xl">{vod.data.playerName}</div>
                            </div>
                            <div className="flex items-center gap-4">
                              <div className="text-xl">{vod.data.kda}</div>
                              <img className="h-20 rounded-lg" src={getChampionImg(vod.data.championName)} alt={vod.data.championName} />
                              <div className="text-2xl">VS</div>
                              <img className="h-20 rounded-lg" src={getChampionImg(vod.data.enemyChampionName)} alt={vod.data.enemyChampionName} />
                            </div>
                            <div className="badge badge-primary text-nowrap">
                              {vod.data.type}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className='px-4'>
            <h1 className="text-3xl mb-4 flex bg-text">Popular Champions</h1>
            <div className="flex xl:block flex-wrap xl:flex-nowrap justify-center">
              {champions.length === 0 ? (
                <div className="">
                  <div className="skeleton w-full md:w-96 h-36 bg-base-300 shadow-xl m-4"></div>
                  <div className="skeleton w-full md:w-96 h-36 bg-base-300 shadow-xl m-4 "></div>
                  <div className="skeleton w-full md:w-96 h-36 bg-base-300 shadow-xl m-4 "></div>
                </div>
              ) : (
                champions.map(champion =>
                  <div className="card w-full md:w-96 bg-base-300 shadow-xl m-4">
                    <Link to={`/matches/${champion.championName}`}> {/* Wrap each item with Link */}
                      <div className="card-body">
                        <div className="flex flex-wrap gap-10">
                          <figure>
                            <img src={getChampionImg(champion.championName)} alt={champion.championName} className="rounded-lg h-20" />
                          </figure>
                          <div className="" >
                            <h1 className="card-title mb-2">{champion.championName}</h1>
                            <div className="flex">
                              {champion.roles.map((role) => (
                                <figure>
                                  <img src={getRoleImg(role)} alt="Role Image" className="object-contain w-10 h-10" />
                                </figure>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  );



};

export default Home;