//import './Matches.css'; // Import CSS file

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Link } from 'react-router-dom';
// Import the functions you need from the SDKs you need
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.2/firebase-app.js";
import { getFirestore } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-firestore.js';

import { collection, getDocs, where, query, getDoc, doc, updateDoc, Timestamp, limit, or, and, orderBy } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-firestore.js';

const firebaseConfig = {
    apiKey: "AIzaSyCM3NrGza2-Upzx6-ET9MTPAymqdhEigr8",
    authDomain: "lolvods-c3104.firebaseapp.com",
    projectId: "lolvods-c3104",
    storageBucket: "lolvods-c3104.appspot.com",
    messagingSenderId: "970001008408",
    appId: "1:970001008408:web:98f145176ac41e4de9006d",
    measurementId: "G-HQEWVSJ6J7"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Champions = () => {
    window.scrollTo(0, 0);

    const { param } = useParams();

    // State for the selected filter option
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [noEntries, setNoEntries] = useState(false);
    // State for the search query
    const [playerQuery, setPlayerQuery] = useState("");
    const [championQuery, setChampionQuery] = useState("");

    // State for vods
    const [vods, setVods] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setNoEntries(false);
            var vodsData = [];
            var q = null
            if (playerQuery != "" && championQuery != "" && selectedFilter > 0) {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), and(where("playerName", "==", playerQuery), or(where("championName", "==", championQuery), where("enemyChampionName", "==", championQuery)), where("role", "==", selectedFilter)));
            } else if (playerQuery != "" && championQuery != "") {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), and(where("playerName", "==", playerQuery), or(where("championName", "==", championQuery), where("enemyChampionName", "==", championQuery))));
            } else if (playerQuery != "" && selectedFilter > 0) {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), and(where("playerName", "==", playerQuery), where("role", "==", selectedFilter)));
            } else if (championQuery != "" && selectedFilter > 0) {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), and(or(where("championName", "==", championQuery), where("enemyChampionName", "==", championQuery)), where("role", "==", selectedFilter)));
            } else if (selectedFilter > 0) {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), where("role", "==", selectedFilter));
            } else if (playerQuery != "") {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), where("playerName", "==", playerQuery));
            } else if (championQuery != "") {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), or(where("championName", "==", championQuery), where("enemyChampionName", "==", championQuery)));
            } else {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20));
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                var vod = { id: doc.id, data: doc.data() }
                vodsData.push(vod);
            });
            if (vodsData.length == 0) {
                setNoEntries(true);
            }
            setVods(vodsData);

        };
        fetchData();
    }, [playerQuery, championQuery, selectedFilter]);

    useEffect(() => {
        setNoEntries(false);
        const fetchData = async () => {
            var vodsData = [];
            var q = null
            if (param != undefined) {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20), or(where("championName", "==", param), where("enemyChampionName", "==", param), where("playerName", "==", param)));
            } else {
                q = query(collection(db, "matches"), orderBy("log.gameEnded", "desc"), limit(20));
            }
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                var vod = { id: doc.id, data: doc.data() }
                vodsData.push(vod);
            });
            if (vodsData.length == 0) {
                setNoEntries(true);
            }
            setVods(vodsData);
        };
        fetchData();

    }, []);


    function getRoleImg(roleNumber) {
        return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/roles%2Frole-${roleNumber}.webp?alt=media&token=c1e1c517-351a-4148-82bc-2a632ffa6a3d`
    }

    function getChampionImg(championName) {
        return `https://ddragon.leagueoflegends.com/cdn/14.7.1/img/champion/${championName}.png`
    }
    return (
        <div className='bg-base-100 min-h-screen flex justify-center'>
            <div className="max-w-screen-2xl mx-auto px-4">
                {/* Filtering options, search bar, and the list */}
                <div className="pb-2 flex flex-wrap gap-4 justify-between mt-10 px-2">
                    <div className="join start">
                        {Array.from({ length: 5 }, (_, index) => (
                            <div className={`join-item btn ${selectedFilter === index + 1 ? 'active' : ''}`} name="options"
                                onClick={() => {
                                    setSelectedFilter(selectedFilter === index + 1 ? 0 : index + 1);
                                }}>
                                <img className="w-8 h-8 sm:w-10 sm:h-10" src={getRoleImg(index + 1)} aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                </img>
                            </div>
                        ))}
                    </div>
                    <div className='gap-4 flex flex-wrap justify-center'>
                        <div>
                            <input
                                type="text"
                                placeholder="Player..."
                                value={playerQuery}
                                onChange={(e) => setPlayerQuery(e.target.value)}
                                className="input input-bordered"
                            />
                        </div>
                        <div>
                            <input
                                type="text"
                                placeholder="Champion..."
                                value={championQuery}
                                onChange={(e) => setChampionQuery(e.target.value)}
                                className="input input-bordered"
                            />
                        </div>

                    </div>

                </div>
                <div className='divider'></div>
                {/* List of VODs */}
                <div className="grid grid-cols-1 gap-4 px-4" >
                    {vods.length === 0 ? (
                        noEntries ? (
                            < div role="alert" className="alert w-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <span>No items found.</span>
                            </div>
                        ) : (

                            <div className='justify-center'>
                                {Array.from({ length: 6 }, (_, index) => (
                                    <div className="skeleton w-full h-36 bg-base-300 shadow-xl m-4"></div>
                                ))}
                            </div>
                        )
                    ) : (

                        vods.map((vod) => (
                            <div key={vod.id} className="card neutral bg-base-300 shadow-xl">
                                <Link to={`/vod/${vod.id}`}>
                                    <div className="card-body">
                                        <div className="flex flex-col md:flex-row gap-4 items-center justify-between">
                                            <div className="flex items-center">
                                                <img className="h-24" src={`https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/images%2F${vod.data.playerName}.webp?alt=media&token=3bf086d9-8875-4680-b7f2-60c19c26b2f8`} alt={vod.data.playerName} />
                                                <img className="h-16" src={getRoleImg(vod.data.role)} alt={vod.data.role} />
                                                <div className="ml-2 text-2xl">{vod.data.playerName}</div>
                                            </div>
                                            <div className="flex items-center gap-4">
                                                <div className="text-xl">{vod.data.kda}</div>
                                                <img className="h-20 rounded-lg" src={getChampionImg(vod.data.championName)} alt={vod.data.championName} />
                                                <div className="text-2xl">VS</div>
                                                <img className="h-20 rounded-lg" src={getChampionImg(vod.data.enemyChampionName)} alt={vod.data.enemyChampionName} />
                                            </div>
                                            <div className="badge badge-primary text-nowrap">
                                                {vod.data.type}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>


            </div>
        </div >
    );
};

export default Champions;
