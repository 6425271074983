import React from "react";
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  window.scrollTo(0, 0);

  return (
    <div className="flex justify-center">
      <div className="m-4">
        <Link to={`/`}>
          <button className="btn btn-primary btn-outline" href="/">Back</button>
        </Link >
        <div className="bg-base-300 rounded-2xl max-w-2xl w-full mt-2 p-5">
          <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
          <p className="mb-6">
            By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use.
          </p>
          <ol>
            <li className="mb-6">
              <h2 className="text-xl font-semibold">Use of Website:</h2>
              <p>You agree to use this website and its content only for lawful purposes and in accordance with these terms of service. By using the website, you consent to the collection and processing of your data for analytics purposes, as described in our <a href="privacy-policy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">User Conduct:</h2>
              <p>You agree not to engage in any conduct that violates the rights of others or disrupts the functioning of the website. You are solely responsible for any data you provide or interactions you have on the website.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Limitation of Liability:</h2>
              <p>lolvods.gg shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of this website.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Indemnification:</h2>
              <p>You agree to indemnify and hold harmless lolvods.gg and its affiliates, officers, directors, employees, and agents from any claims, damages, liabilities, and expenses arising out of your use of this website or violation of these terms of service.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Governing Law:</h2>
              <p>These terms of service shall be governed by and construed in accordance with the laws of Germany and EU.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Contact Us:</h2>
              <p>If you have any questions about our privacy policy, please contact us at:</p>
              <address className="ml-10 mt-5">
                Jonas Bauerfeld<br />
                jpukschitz+lolvods@gmail.com<br />
                or<br />
                X: @lolvodsgg
              </address>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
