import React from "react";
import { Link } from 'react-router-dom';



const PrivacyPolicy = () => {
  window.scrollTo(0, 0);

  return (
    <div className="flex justify-center">
      <div className="m-4">
        <Link to={`/`}>
          <button className="btn btn-primary btn-outline" href="/">Back</button>
        </Link >
        <div className="bg-base-300 rounded-2xl max-w-2xl w-full mt-2 p-5">

          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          <p>Thank you for visiting our website. Here at lolvods.gg, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and disclose information when you visit our website.</p>

          <ol className="mt-6">
            <li className="mb-6">
              <h2 className="text-xl font-semibold">Information Collection:</h2>
              <p>We collect certain non-personal information when you visit our website, limited to the view of the page. We use Plausible.io to collect and analyze this data in order to improve our website's performance and user experience.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Information Usage:</h2>
              <p>The information collected is used solely for analytics purposes to analyze website traffic and user behavior. This helps us understand how visitors interact with our website and allows us to make improvements to enhance your experience.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Information Sharing:</h2>
              <p>We share website usage data with Plausible.io for analytics purposes. Plausible.io may also collect and process data according to its own privacy policy.
                Please refer to <a className="link" href="https://plausible.io/data-policy" target="_blank">Plausible's Policy</a> for more information.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Third-Party Links:</h2>
              <p>Our website may contain links to third-party websites, such as Twitch or Plausible.io. Please note that once you click on these links, you will be subject to the privacy policies of those third-party websites. We are not responsible for the privacy practices or content of these external sites.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Changes to This Policy:</h2>
              <p>We reserve the right to update or change our privacy policy at any time. Any changes will be posted on this page.</p>
            </li>

            <li className="mb-6">
              <h2 className="text-xl font-semibold">Contact Us:</h2>
              <p>If you have any questions about our privacy policy, please contact us at:</p>
              <address className="ml-10 mt-5">
                Jonas Bauerfeld<br />
                jpukschitz+lolvods@gmail.com<br />
                or<br />
                X: @lolvodsgg
              </address>
            </li>
          </ol>
        </div>
      </div>
    </div>

  );


};

export default PrivacyPolicy;

