import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.2/firebase-app.js";
import { getFirestore, collection, getDocs, where, query, getDoc, doc } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-firestore.js';

const firebaseConfig = {
    apiKey: "AIzaSyCM3NrGza2-Upzx6-ET9MTPAymqdhEigr8",
    authDomain: "lolvods-c3104.firebaseapp.com",
    projectId: "lolvods-c3104",
    storageBucket: "lolvods-c3104.appspot.com",
    messagingSenderId: "970001008408",
    appId: "1:970001008408:web:98f145176ac41e4de9006d",
    measurementId: "G-HQEWVSJ6J7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Champions = () => {
    window.scrollTo(0, 0);

    const [championsList, setChampionsList] = useState([]);
    const [championsListAll, setChampionsListAll] = useState([]);
    const [noEntries, setNoEntries] = useState(false);

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (selectedFilter > 0) {
            var tempList = [];
            championsListAll.forEach(champion => {
                champion.roles.forEach(role => {
                    if (role == selectedFilter) {
                        tempList.push(champion);
                    }
                })
            })
            if(tempList.length === 0){
                setNoEntries(true);
            }
            setChampionsList(tempList);
        } else {
            setChampionsList(championsListAll);
        }

    }, [selectedFilter]);

    useEffect(() => {
        setNoEntries(false);
        if (searchQuery != "") {
            var tempList = [];
            championsListAll.forEach(champion => {
                if (champion.championName_caseInsensitive.includes(searchQuery.toLowerCase())) {
                    tempList.push(champion);
                }
            })
            if(tempList.length === 0){
                setNoEntries(true);
            }
            setChampionsList(tempList);
        } else {
            setChampionsList(championsListAll);
        }
    }, [searchQuery]);

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDoc(doc(db, "stats", "champions"));
            const championsData = querySnapshot.data().allChampions
            setChampionsListAll(championsData);
            setChampionsList(championsData);
        };
        fetchData();
    }, []);

    function getRoleImg(roleNumber) {
        return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/roles%2Frole-${roleNumber}.webp?alt=media&token=c1e1c517-351a-4148-82bc-2a632ffa6a3d`
    }

    function getChampionImg(championName) {
        return `https://ddragon.leagueoflegends.com/cdn/14.7.1/img/champion/${championName}.png`
    }

    return (
        <div className='bg-base-100 min-h-screen flex justify-center'>
            <div className="max-w-screen-xl mx-auto px-4 w-full">
                <div className="pb-2 flex flex-wrap gap-4 justify-center md:justify-between mt-10 px-2">
                    <div className="join start">
                        {Array.from({ length: 5 }, (_, index) => (
                            <div className={`join-item btn ${selectedFilter === index + 1 ? 'active' : ''}`} name="options"
                                onClick={() => {
                                    setSelectedFilter(selectedFilter === index + 1 ? 0 : index + 1);
                                }}>
                                <img className="w-8 h-8 sm:w-10 sm:h-10" src={getRoleImg(index + 1)} aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                </img>
                            </div>
                        ))}
                    </div>
                    <input
                        type="text"
                        placeholder="Player..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="input input-bordered w-80"
                    />
                </div>
                <div className='divider'></div>
                <div className="flex flex-wrap justify-center">
                    {championsList.length == 0 ? (
                        noEntries ? (
                            <div role="alert" className="alert w-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <span>No items found.</span>
                            </div>
                        ) : (
                            <div className='flex flex-wrap'>
                                {Array.from({ length: 9 }, (_, index) => (
                                    <div className="skeleton  w-48 h-56 bg-base-300 shadow-xl m-4"></div>
                                ))}
                            </div>
                        )
                    ) : (
                        championsList.map((champion) => (
                            <div className="card w-auto sm:w-48 shadow-xl m-2">
                                <Link to={`/matches/${champion.championName}`}> {/* Wrap each item with Link */}
                                    <div className="card-body ">
                                        <div className='flex sm:block justify-evenly items-center gap-4'>
                                            <div className=''>
                                                <figure>
                                                    <img src={getChampionImg(champion.championName)} alt={champion.championName} className='rounded-lg' />
                                                </figure>
                                                <div className="card-title justify-center mt-2" >
                                                    <h1>{champion.championName}</h1>
                                                </div>
                                            </div>
                                            <div className='justify-center items-center sm:flex gap-2 mt-2 '>
                                                {champion.roles.map((role) => (
                                                    <img src={getRoleImg(role)} alt="Role Image" className='object-contain w-10 h-10' />
                                                ))}

                                            </div>
                                        </div>

                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );

};

export default Champions;
