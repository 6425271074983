import React from "react";
import { Link } from 'react-router-dom';

const Imprint = () => {
    return (
        <div className="flex justify-center">
            <div className="m-4 w-96">
                <Link to={`/`}>
                    <button className="btn btn-primary btn-outline" href="/">Back</button>
                </Link >
                <div className="bg-base-300 rounded-2xl max-w-2xl w-full h-full mt-2 p-5">
                    <h1 className="text-3xl font-bold mb-6">Imprint</h1>
                    <address className="ml-4">
                        Jonas Bauerfeld<br />
                        Lauenburger Allee 6<br />
                        47269 Duisburg<br />
                        +49 152 08590163<br/>
                        jonas.bauerfeld@gmx.de
                    </address>
                </div>
            </div>
        </div>
    );
};

export default Imprint;
