import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.5.2/firebase-app.js";
import { getFirestore, collection, getDocs, where, query } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-firestore.js';

const firebaseConfig = {
    apiKey: "AIzaSyCM3NrGza2-Upzx6-ET9MTPAymqdhEigr8",
    authDomain: "lolvods-c3104.firebaseapp.com",
    projectId: "lolvods-c3104",
    storageBucket: "lolvods-c3104.appspot.com",
    messagingSenderId: "970001008408",
    appId: "1:970001008408:web:98f145176ac41e4de9006d",
    measurementId: "G-HQEWVSJ6J7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Pros = () => {
    window.scrollTo(0, 0);

    const [proListAll, setProListAll] = useState([]);
    const [proList, setProList] = useState([]);
    const [noEntries, setNoEntries] = useState(false);

    const [selectedFilter, setSelectedFilter] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setNoEntries(false);
        var tempList = [];
        if (selectedFilter > 0 && searchQuery != "") {
            proListAll.map(pro => {
                if (pro.data.role == selectedFilter && pro.data.playerName.toLowerCase().includes(searchQuery.toLowerCase())) {
                    tempList.push(pro);
                }
            })
            if(tempList.length === 0){
                setNoEntries(true);
            }
            setProList(tempList);
        } else if (selectedFilter > 0) {
            proListAll.map(pro => {
                if (pro.data.role == selectedFilter) {
                    tempList.push(pro);
                }
            })
            if(tempList.length === 0){
                setNoEntries(true);
            }
            setProList(tempList);

        } else if (searchQuery != "") {
            proListAll.map(pro => {
                if (pro.data.playerName.toLowerCase().includes(searchQuery.toLowerCase())) {
                    tempList.push(pro);
                }
            })
            if(tempList.length === 0){
                setNoEntries(true);
            }
            setProList(tempList);

        } else {
            setProList(proListAll);
        }
    }, [selectedFilter, searchQuery]);


    useEffect(() => {
        const getPros = async () => {
            setNoEntries(false);
            const querySnapshot = await getDocs(collection(db, "players"));
            const pros = querySnapshot.docs.map(doc => ({ data: doc.data() }));
            let array = Array.from(pros, (data) => data);
            setProList(array);
            setProListAll(array);
        };
        getPros();
    }, []);

    function getRoleImg(roleNumber) {
        return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/roles%2Frole-${roleNumber}.webp?alt=media&token=c1e1c517-351a-4148-82bc-2a632ffa6a3d`
    }

    function getProImg(proName) {
        return `https://firebasestorage.googleapis.com/v0/b/lolvods-c3104.appspot.com/o/images%2F${proName}.webp?alt=media&token=3bf086d9-8875-4680-b7f2-60c19c26b2f8`
    }

    return (
        <div className='bg-base-100 min-h-screen flex justify-center'>
            <div className="max-w-screen-xl mx-auto px-4 w-full">
                <div className="pb-2 flex flex-wrap gap-4 justify-center md:justify-between mt-10 px-2">
                    <div className="join start">
                        {Array.from({ length: 5 }, (_, index) => (
                            <div className={`join-item btn ${selectedFilter === index + 1 ? 'active' : ''}`} name="options"
                                onClick={() => {
                                    setSelectedFilter(selectedFilter === index + 1 ? 0 : index + 1);
                                }}>
                                <img className="w-8 h-8 sm:w-10 sm:h-10" src={getRoleImg(index + 1)} aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                                </img>
                            </div>
                        ))}
                    </div>
                    <input
                        type="text"
                        placeholder="Player..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="input input-bordered w-80"
                    />
                </div>
                <div className='divider'></div>
                <div className="flex flex-wrap justify-center gap-4">
                    {proList.length === 0 ? (
                        noEntries ? (
                            <div role="alert" className="alert w-1/2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                <span>No items found.</span>
                            </div>
                        ) : (
                            <div className='flex flex-wrap'>
                                {Array.from({ length: 9 }, (_, index) => (
                                    <div className="skeleton w-64 h-64 bg-base-300 shadow-xl m-4"></div>
                                ))}
                            </div>
                        )
                    ) : (
                        proList.map((pro) => (
                            <div className="card w-64 shadow-xl m-4 h-64">
                                <Link to={`/matches/${pro.data.playerName}`} key={pro.id}> {/* Wrap each item with Link */}
                                    <div className="card-body">
                                        <figure>
                                            <img src={getProImg(pro.data.playerName)} alt={pro.data.playerName} className='object-contain h-40' />
                                        </figure>
                                        <div className="card-title justify-center" >
                                            <h1 className='text-2xl'>{pro.data.playerName}</h1>
                                            <figure>
                                                <img src={getRoleImg(pro.data.role)} alt="Role Image" className='object-contain w-10 h-10' />

                                            </figure>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    )}
                </div>
            </div>
        </div>
    );

};

export default Pros;
